import React from 'react'

const footage = () => {
  return (
    <>
    <section className='bg-[#1e5a41] text-white h-[7vh] w-full text-center flex flex-col justify-center '>
        <p className='text-center text-white text-xs md:text-sm'>© 2024 Temerachi Coffee Export. All Rights Reserved.</p>
    </section>
      
    </>
  )
}

export default footage
